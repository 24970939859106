import { RemixBrowser } from "@remix-run/react";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import { hydrateLang } from "remix-paraglidejs/client";
import { availableLanguageTags, setLanguageTag } from "$paraglide/runtime";
import { setDateFnsLocale } from "./utils/locale";

async function hydrate() {
	const lang = hydrateLang("language-tag", availableLanguageTags);
	setLanguageTag(lang);
	setDateFnsLocale(lang);

	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<RemixBrowser />
			</StrictMode>,
		);
	});
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	// Safari doesn't support requestIdleCallback
	// https://caniuse.com/requestidlecallback
	window.setTimeout(hydrate, 1);
}
